<template>
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <div @click.prevent="showStopAppModal" v-on="on">
                    <slot></slot>
                </div>
            </template>
            Stop application
        </v-tooltip>
    </div>
</template>

<script>
export default {
    name: 'StopApplication',
    props: {
        appName: String,
        appId: String,
        sessionId: String,
        isShared: Boolean
    },
    methods: {
        showStopAppModal() {
            if ((this.appId && this.$route.query.stopAid !== this.appId) || (this.sessionId && this.$route.query.stopSessionId !== this.sessionId)) {
                this.$router.push({ query: { stopAid: this.appId, stopSessionId: this.sessionId } })
            }
            this.$store.dispatch('showGlobalDialog', {
                dialogText: this.isShared
                    ? `Are you sure you would like to stop application ${this.appName}?<p class="my-2 font-weight-bold warning--text">This application allows multiple users to connect simultaneously, so stopping it might affect other users.</p> <p>It will stop automatically once idle.</p>`
                    : `Are you sure you would like to stop application ${this.appName}?`,
                dialogAction: ['stop-application', 'cancel'],
                dialogTitle: 'Confirm application stop'
            })
        }
    }
}
</script>
